//todoorder.js
import request from '@/utils/request'
import qs from 'qs'

// 代办订单数据
export function todoOrder (id,page,limit) {
  let data = {
  id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/orderlist',
    method: 'post',
    data
  })
}
//订单分配
export function orderDistribute (works_id,staff_id) {
  let data = {
   works_id,staff_id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/orderdistribute',
    method: 'post',
    data
  })
}
//二次订单
export function orderListTwo (id,page,limit) {
  let data = {
   id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/orderlisttwo',
    method: 'post',
    data
  })
}
//查询员工信息
export function staffList (fwsid) {
  let data = {
   fwsid
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/showstafftwo',
    method: 'post',
    data
  })
}

