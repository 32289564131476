<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="新订单" name="first">
        <div class="left">
          <div class="left_title">
            <span>共：{{ total }}条</span>
            <el-button type="danger" @click="receive()">分配</el-button>
          </div>
          <div class="left_cont">
            <div>
              <el-table
                ref="multipleTable"
                :header-cell-style="headeRowClass"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                :data="tableData"
              >
                <el-table-column type="selection" align="center">
                </el-table-column>

                <el-table-column
                  prop="title"
                  label="文章标题"
                  sortable
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="editor" label="作者" sortable>
                </el-table-column>
                <el-table-column prop="staffName" label="订单所属" sortable>
                </el-table-column>
                <el-table-column
                  label="日期"
                  show-overflow-tooltip
                  sortable
                  align="center"
                >
                  <template slot-scope="scope">{{
                    scope.row.operation_time
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="order_No"
                  label="订单号"
                  sortable
                  align="center"
                ></el-table-column>
                <el-table-column prop="attribute" label="订单状态" sortable>
                  <template slot-scope="scope">
                    <span v-if="scope.row.attribute == 0">普通</span>
                    <span v-if="scope.row.attribute >= 1">加急</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <el-dialog
            title="分配给"
            :visible.sync="dialog"
            width="503px"
            :close-on-click-modal="false"
          >
            <div>
              <el-form ref="form" :model="form">
                <el-form-item>
                  <el-select
                    filterable
                    clearable
                    v-model="form.region"
                    placeholder="请选择员工"
                    style="width: 80%"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.realname"
                      :value="item.id"
                    >
                      <span style="float: left">{{ item.realname }}</span>
                      <span style="float: right; color: red; font-size: 13px"
                        >处理中：{{ item.geshu }}件</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialog = false">取 消</el-button>
              <el-button type="danger" @click="distribute()" :loading="loading"
                >确 定</el-button
              >
            </div>
          </el-dialog>

          <div class="orderPage">
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange"
              :page-size="pagesize"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <!--二次订单-->
      <el-tab-pane label="二次订单" name="second">
        <div class="right">
          <div class="left_title">
            <span>共：{{ total2 }}条</span>
            <el-button type="danger" @click="receive2()">分配</el-button>
          </div>
          <div class="left_cont">
            <div>
              <el-table
                ref="multipleTable"
                :header-cell-style="headeRowClass"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange2"
                :data="tableData2"
              >
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="文章标题"
                  :show-overflow-tooltip="true"
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="editor"
                  label="作者"

                  sortable
                >
                </el-table-column>
                <el-table-column
                  label="日期"
                  prop="operation_time"
                  show-overflow-tooltip
                  sortable
                  align="center"
                >
                </el-table-column>
                 <el-table-column prop="staffName" label="订单所属" sortable></el-table-column>
                <el-table-column
                  prop="order_No"
                  label="订单号"
                  sortable
                  align="center"
                ></el-table-column>
                <el-table-column prop="attribute" label="订单状态" sortable>
                  <template slot-scope="scope">
                    <span v-if="scope.row.attribute == 0">普通</span>
                    <span v-if="scope.row.attribute >= 1">加急</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!--弹窗-->
          <el-dialog
            title="分配给"
            :visible.sync="dialog2"
            width="503px"
            :close-on-click-modal="false"
          >
            <div>
              <el-form ref="form" :model="form">
                <el-form-item>
                  <el-select
                    filterable
                    v-model="form2.region"
                    clearable
                    placeholder="请选择员工"
                    style="width: 80%"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.realname"
                      :value="item.id"
                    >
                      <span style="float: left">{{ item.realname }}</span>
                      <span style="float: right; color: red; font-size: 13px"
                        >处理中：{{ item.geshu }}件</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialog2 = false">取 消</el-button>
              <el-button type="danger" @click="distribute2()" :loading="loading"
                >确 定</el-button
              >
            </div>
          </el-dialog>
          <!--二次订单分页-->
          <div class="orderPage">
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange2"
              :page-size="pagesize2"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change2"
              :total="total2"
              background
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>


import { todoOrder } from '@/API/toDoOrder';
import { orderDistribute } from '@/API/toDoOrder';
import { orderListTwo } from '@/API/toDoOrder';
import { staffList } from '@/API/toDoOrder';
export default {
  data () {
    return {
      form: {
        name: '',
        region: ''
      },
      form2: {
        name: '',
        region: ''
      },
      //选项卡第一个默认选中
      orderid: [],
      orderid2: [],//被选中的orderid
      activeName: 'first',
      total: 0, //新订单数据总数
      total2: 0, //二次订单数据总数
      pagesize: 10,
      pagesize2: 10,//每页的数据条数
      loading: false,
      currentPage: 1, //默认开始页面
      currentPage2: 1, //默认开始页面
      dialog: false,
      dialog2: false, //默认不显示
      //待处理订单表格数据
      tableData: [],
      //二次订单表格数据
      tableData2: [],
      multipleSelection: [],//所有被选中的数据
      multipleSelection2: [],
      //员工数据
      options: []
    }
  },
  methods: {
    //选项卡切换
    handleClick (tab, event) {
      console.log(tab, event);
    },
    //表格复选框选中事件
    //			toggleSelection(rows) {
    //				if(rows) {
    //					rows.forEach(row => {
    //						this.$refs.multipleTable.toggleRowSelection(row);
    //					});
    //				} else {
    //					this.$refs.multipleTable.clearSelection();
    //				}
    //			},
    handleSelectionChange (val) {
      this.multipleSelection = val;
      console.log(val)
      this.orderid = [];
      for (let i of this.multipleSelection) {
        this.orderid.push(i.id)
      }
      console.log(this.orderid)
    },
    handleSelectionChange2 (val) {
      this.multipleSelection2 = val;
      this.orderid2 = [];
      console.log(this.multipleSelection2)
      for (let i of this.multipleSelection2) {
        this.orderid2.push(i.id)
      }
      console.log(this.orderid2)
    },
    // 更改表头样式
    headeRowClass ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    // 代办订单分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.orderTodo()
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val
      this.orderTodo()
    }, handleSizeChange2 (val) {
      console.log(val);
      this.pagesize2 = val;
      this.ordertwo()
    },
    // 二次订单分页
    current_change2: function (currentPage) {
      this.currentPage2 = currentPage;
      this.ordertwo()
    },
    //弹窗
    receive: function (row) {
      if (this.orderid.length == 0) {
        this.$message.error("未勾选订单")
      } else {
        //显示弹框
        this.dialog = true;
      }

    },
    //二次订单弹窗
    receive2: function (row) {
      if (this.orderid2.length == 0) {
        this.$message.error("未勾选订单")
      } else {
        //显示弹框
        this.dialog2 = true;
      }

    },
    orderTodo: function () {
      todoOrder(localStorage.getItem("FuWuId"), this.currentPage, this.pagesize).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        console.log(this.tableData)
      })
    },
    ordertwo: function () {
      orderListTwo(localStorage.getItem("FuWuId"), this.currentPage2, this.pagesize2).then(res => {
        this.total2 = res.data.total
        this.tableData2 = res.data.data


      })
    },
    distribute () {
      this.dialog = false
      this.orderid = this.orderid.join(",")
      orderDistribute(this.orderid, this.form.region).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$message('分配成功');
          this.orderTodo()
        }
      })
    },
    distribute2 () {
      this.dialog2 = false
      this.orderid2 = this.orderid2.join(",")
      console.log(this.orderid2, this.form2.region);
      orderDistribute(this.orderid2, this.form2.region).then(res => {
        if (res.data.code == 0) {
          this.$message('分配成功');
          this.ordertwo()
        }
      })
    },
    selectStaff () {
      staffList(localStorage.getItem("FuWuId")).then(res => {
        this.options = res.data.data
        console.log(this.options)
        // for(let i=0;i<this.options.length;i++){
        // 	this.options[i].realname=this.options[i].realname+"（处理中："+this.options[i].geshu+"件）"
        // }
      })
    }
  },
  mounted () {
    this.orderTodo()
    this.ordertwo()
    this.selectStaff()
  }
};

</script>

<style>
.left,
.right {
  width: 98%;
  height: auto;
}

.left_title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left_title span {
  margin-right: 10px;
}

.allocate {
  width: 100px;
  height: 40px;
  background: #ff1918;
  border-radius: 4px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  margin-left: 20px;
}

.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>